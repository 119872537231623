import React, { Suspense, lazy } from 'react'
import Container from 'react-bootstrap/Container';
import CleaningList from '../components/CleaningList';
import PageTitle from '../components/PageTitle';

import Spinner from 'react-bootstrap/Spinner';
const LazySoTellUs = lazy(() => import('../components/SoTellUs'));

export default function Move(){
    return (
        <Container>
            <PageTitle title='DHCS - Move In/Out Clean' />

            <h1>Move In/Out Clean</h1>
            <div className='clean'>
                <div className='space'>
                    <p className='fw-500'>Move Into A Clean And Sanitized New Home With Our Move-In Cleaning Service</p>
                    <p>Getting a professional move-in cleaning is like giving your new place a fresh, squeaky-clean welcome hug. Our move-in cleaning pros have a knack for turning dusty rooms into sparkling sanctuaries. They'll sanitize every nook and cranny, banish hidden allergens, and make your space smell as inviting as a freshly baked batch of cookies.</p>
                    <p>A move-in clean is also a sanity-saver, sparing you from the hassle of scrubbing and mopping when you're already swamped with the chaos of moving. So, go ahead and treat yourself to the luxury of a move-in cleaning—your future self will thank you for starting your new chapter on a spotless note.</p>

                    <CleaningList />
                    
                    <p>We use top-quality cleaning products and state-of-the-art equipment to ensure your home not only looks immaculate but is also a healthier environment for you and your family.</p>
                    <p>* We do not climb higher than a 2-step ladder. Higher items will only be dusted w/ an extension duster we cannot move furniture, but we will try to reach any visible places either by hand or with an extension duster. Most homes have at least one chandelier or vaulted ceiling fan that we cannot reach by hand.</p>
                    <p>* We cannot guarantee the results of mini-blind cleaning. Please allow for some dust resettlement after we leave, we try to limit the dust in the air but cannot prevent this entirely.</p>
                </div>
                <Suspense fallback={
                    <div>
                        <Spinner animation="border" variant="secondary" />
                    </div>
                }>
                    <LazySoTellUs/>
                </Suspense>
            </div>
            
        </Container>
    )
}