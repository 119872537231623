import React from 'react'
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import PageTitle from '../components/PageTitle';

export default function CleaningChecklist(){
    return (
        <Container>
            <PageTitle title='DHCS - Cleaning Checklist' />
            
            <h1>Cleaning Checklist</h1>
           <Table hover responsive>
                <thead>
                <tr className='table-primary'>
                        <th></th>
                        <th>STANDARD CLEANING SERVICE</th>
                        <th>DEEP CLEAN</th>
                        <th>MOVE IN/OUT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='table-secondary'>
                        <th>KITCHEN</th>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Clean countertops</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Dust countertop items</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td>N/A</td>
                    </tr>
                    <tr>
                        <td>Clean appliances; polish stainless</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Clean oven interior (Extra)</td>
                        <td>Add-on</td>
                        <td>Add-on</td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Clean refrigerator interior (Extra)</td>
                        <td>Add-on</td>
                        <td>Add-on</td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Spot clean cabinets</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Wash cabinet fronts</td>
                        <td><i className='bi bi-x-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Clean inside cabinets & drawers</td>
                        <td>Add-on</td>
                        <td>Add-on</td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Sanitize sink; polish fixtures</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>

                    <tr className='table-secondary'>
                        <th>BATHROOMS</th>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Scrub tub/shower; polish fixtures</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Clean countertops</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Clean inside cabinets & drawers</td>
                        <td><i className='bi bi-x-lg' /></td>
                        <td><i className='bi bi-x-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Sanitize sinks; polish fixtures</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Clean mirrors</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Sanitize toilet and toilet area</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>

                    <tr className='table-secondary'>
                        <th>BEDROOMS</th>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Change linen; make beds</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td>N/A</td>
                    </tr>

                    <tr className='table-secondary'>
                        <th>ALL ROOMS</th>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Remove cobwebs</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Dust ceiling fans and light fixtures</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Dust wall art</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td>N/A</td>
                    </tr>
                    <tr>
                        <td>Dust knick-knacks and lamps</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td>N/A</td>
                    </tr>
                    <tr>
                        <td>Dust furniture; polish wood furniture</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td>N/A</td>
                    </tr>
                    <tr>
                        <td>Dust blinds</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Wash Blinds (Extra)</td>
                        <td>Add-on</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Dust window sills</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Wash Window Sills</td>
                        <td>Add-on</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Dust doors and door frames</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Wash doors and door frames</td>
                        <td><i className='bi bi-x-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Dust Baseboards</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Wash Baseboards</td>
                        <td>Add-on</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Empty trash and reline trash cans</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td>N/A</td>
                    </tr>
                    <tr>
                        <td>Vacuum floors</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    <tr>
                        <td>Mop hard-surface floors</td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                        <td><i className='bi bi-check-lg' /></td>
                    </tr>
                    
                </tbody>
            </Table>
        </Container>
    )
}