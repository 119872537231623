import React from 'react'

export default function SoTellUs({height}) {
    return (
        <div
            width='876'
            height='407'
            style={{maxWidth: '100%', height: 'auto'}}
        >
            <iframe 
                id='stuifw01' 
                title="Dani's Home Cleaning Services' SoTellUs reviews"
                src='https://sotellus.com/iframe/danis-home-cleaning-services/' 
                width='100%'
                height={height ? height : '400' }
            />
        </div>
    )
}