import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import PageTitle from './PageTitle'

export default function Error404(){
    return (
        <Container className='error'>
            <PageTitle title='DHCS' />
            
            <pre>
                <h1>Page Not Found</h1>
                Error 404: We can't find the page you're looking for.
            </pre>
        </Container>
    )
}