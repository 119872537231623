import React, { Suspense, lazy } from 'react'
import Container from 'react-bootstrap/Container';
import PageTitle from '../components/PageTitle'

// Media
import aboutUsLgWeb from '../../media/about-us-lg.webp';
import aboutUsMdWeb from '../../media/about-us-md.webp';
import aboutUsSmWeb from '../../media/about-us-sm.webp';

import Spinner from 'react-bootstrap/Spinner';
const LazySoTellUs = lazy(() => import('../components/SoTellUs'));

export default function About(){
    return (
        <Container>
            <PageTitle title='DHCS - About Us' />

             <h1>Our Story</h1>
             <div className='about'>
                <picture>
                    <source 
                        srcSet={`${aboutUsSmWeb} 516w, ${aboutUsMdWeb} 696w, ${aboutUsLgWeb} 1600w`} 
                        type="image/webp"
                    />
                    <img 
                        src={aboutUsMdWeb} 
                        alt='Juan, Daniela, and their four sons.'
                        width="420" 
                        height="468" 
                        style={{height: 'auto'}}
                        fetchpriority='high'
                    />
                </picture>
                <div className='space'>
                    <p>Founded by Juan and Daniela, a couple who, amidst financial difficulties, saw an opportunity to create a brighter future for their family. Inspired by Daniela's mother's years of experience in the cleaning industry, we embarked on this journey together.</p>
                    <p>Seven years ago, with four children and a dream, we launched our cleaning business from the ground up. It was a challenging start, with Juan and Daniela handling all the cleaning tasks. However, our dedication to providing exceptional service and forming close relationships with our customers has been the cornerstone of our business.</p>
                    <p>Today, we have grown from a team of two to a family of nine dedicated members. Our expansion has allowed us to form specialized teams that uphold our commitment to quality and trustworthiness. Despite our growth, we have maintained the heart of a small family business, priding ourselves on our personal approach and the strong bonds we build with our clients.</p>
                    <p>Our services are simple to schedule, tailored to meet your needs, and delivered with the utmost care and professionalism. We believe in making your life easier and cleaner, one visit at a time. The loyalty of our customers over the last seven years has been the greatest testament to our success, and for that, we are eternally grateful.</p>
                    <p className='fw-500 fst-italic'>Join us on this journey, and let Dani's Home Cleaning Services take care of your home as if it were our own. Because here, you're not just a customer; you're part of the family.</p>
                </div>
             </div>
             <Suspense fallback={
                <div>
                    <Spinner animation="border" variant="secondary" />
                </div>
            }>
                <LazySoTellUs/>
            </Suspense>
        </Container>
    )
}