import React from 'react'
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import PageTitle from '../components/PageTitle';

export default function Faqs(){
    return(
        <Container className='faqs'>
            <PageTitle title='DHCS - FAQs' />

            <h1>FAQs</h1>
            <p>Many of our customers have specific questions about our professional house cleaning services. Below, we've compiled some commonly asked questions that we often address at Danis Home Cleaning Services.</p>
            
            <h2>General Questions</h2>
            <Accordion>
                <Accordion.Item eventKey='0'>
                    <Accordion.Header className='custom-accordion-header'>
                        What is your 100% satisfaction guarantee?
                    </Accordion.Header>
                    <Accordion.Body>
                        Our 100% satisfaction guarantee is our commitment to industry shaking customer service. If for any reason you are unhappy with the service, send us an email or give us a call within 24 hours of the service being rendered. We will then return within 72 hours to rectify any missed areas. If you’re still unhappy after the clean is completed we will refund your money or apply a credit to your account.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header className='custom-accordion-header'>
                        Does someone have to visit my house before I can book a cleaning?
                    </Accordion.Header>
                    <Accordion.Body>
                        Absolutely not! But we do recommend and in home estimate to receive the most accurate quote.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                    <Accordion.Header className='custom-accordion-header'>
                        What if I'm not happy with my cleaning?
                    </Accordion.Header>
                    <Accordion.Body>
                        Please get in touch with us right away and we’ll make arrangements to rectify the situation. We have a satisfaction guarantee that we stand by.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='3'>
                    <Accordion.Header className='custom-accordion-header'>
                        Are cleaning supplies included?
                    </Accordion.Header>
                    <Accordion.Body>
                        We provide the cleaning supplies but we can use your own cleaning supplies to prevent any cross contamination from house to house.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <h2>Scheduling Questions</h2>
            <Accordion>
                <Accordion.Item eventKey='0'>
                    <Accordion.Header className='custom-accordion-header'>
                        Do I have to be home for my cleaning?
                    </Accordion.Header>
                    <Accordion.Body>
                        It’s completely up to you. If you’re not going to be home, make sure you let us know how to access your house.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header className='custom-accordion-header'>
                        When am I charged for the cleaning?                    
                    </Accordion.Header>
                    <Accordion.Body>
                        You are not charged until after the cleaning has been completed. Your invoice will reflect only the amount of time that your cleaner was actually at your home working, rounded-up to the nearest 15 minute increment.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <h2>Cleaning Questions</h2>
            <Accordion>
                <Accordion.Item eventKey='0'>
                    <Accordion.Header className='custom-accordion-header'>
                        What's included in a standard cleaning?
                    </Accordion.Header>
                    <Accordion.Body>
                    Standard Clean. You can also request extra items.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header className='custom-accordion-header'>
                        What services do you offer?
                    </Accordion.Header>
                    <Accordion.Body>
                        We offer a range of services from weekly cleaning to one-time cleaning to move-in and move-out cleaning. 
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                    <Accordion.Header className='custom-accordion-header'>
                        How many cleaners do you send?
                    </Accordion.Header>
                    <Accordion.Body>
                        We send 2-3 cleaners to your home.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

        </Container>
    )
}