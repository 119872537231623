import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

export default function CleaningList({addOns}){
    return (
        <Accordion>
            <Accordion.Item eventKey='0'>
                <Accordion.Header className='custom-accordion-header'>All Room</Accordion.Header>
                <Accordion.Body>
                    <ul>
                        <li>Make beds in bedrooms</li>
                        <li>Dust furniture</li>
                        <li>Feather dust knick-knacks and wall art</li>
                        <li>Dust reachable ceiling fans and light fixtures</li>
                        <li>Dust baseboards</li>
                        <li>Dust doors and door frames</li>
                        <li>Dust blinds & window sills</li>
                        <li>Vacuum floors</li>
                        <li>Mop hard-surface floors</li>
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='1'>
                <Accordion.Header>Kitchen</Accordion.Header>
                <Accordion.Body>
                    <ul>
                        <li>Spot-clean cabinet fronts and backsplash</li>
                        <li>Clean and sanitize countertops.</li>
                        <li>Dust countertop items</li>
                        <li>Clean and polish appliance exteriors (oven, refrigerator, dishwasher)</li>
                        <li>Clean stove top</li>
                        <li>Thoroughly clean both inside and outside of the microwave.</li>
                        <li>Clean and sanitize sinks</li>
                        <li>Polish sink fixtures</li>
                        <li>Dispose of trash and replace the trash can liner</li>
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='2'>
                <Accordion.Header>Bathrooms</Accordion.Header>
                <Accordion.Body>
                    <ul>
                        <li>Clean and sanitize countertops</li>
                        <li>Clean sink and polish fixtures</li>
                        <li>Dust countertop items</li>
                        <li>Clean mirrors</li>
                        <li>Dust light fixtures</li>
                        <li>Clean and sanitize the toilet</li>
                        <li>Clean tubs and showers removing soap scum and light mildew</li>
                        <li>Dispose of trash and replace the trash can liner</li>
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
            {
                addOns && 
                <Accordion.Item eventKey='3'>
                    <Accordion.Header>Add Ons</Accordion.Header>
                    <Accordion.Body>
                        <p className='mb-1'>The following can be added to any cleaning for an additional charge:</p>
                        <ul>
                            <li>Oven interior cleaning</li>
                            <li>Refridgerator/freezer interior cleaning</li>
                            <li>Clean inside cabinets & drawers</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            }
        </Accordion>
    )
}