import React from 'react'
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PageTitle from '../components/PageTitle';

export default function Terms(){
    const [quote, setQuote] = React.useState(false)
    const [valid, setValid] = React.useState([false, 0])
    const [zip, setZip] = React.useState('')
    const zips = [19061,19311,19317,19348,19350,19360,19374,19700,19701,19702,19703,19706,19707,19708,19710,19711,19712,19713,19714,19715,19716,19717,19718,19720,19721,19725,19726,19732,19733,19735,19736,19766,19801,19802,19803,19804,19805,19806,19807,19808,19809,19810,19814,19850,19880,19884,19885,19886,19887,19889,19890,19891,19892,19893,19894,19895,19896,19897,19898,19899]
    const style = {
        border: 'none',
        width: '100%',
        height: '100%',
        overflow: 'hidden !important'
    }
    const handlePaste = (e) => e.preventDefault();
    const handleClick = () => setQuote(true) 

    function handleChange(e) {
        const { value } = e.target;
        if (/^\d*$/.test(value) && value.length <= 5) {
            setZip(value);
            setValid((prevValid) => [prevValid[0], value.length])
        }
    }

    if (zips.includes(Number(zip)) && !valid[0]){
        setValid((prevValid) => [true, prevValid[1]])
    } else if (!zips.includes(Number(zip)) && valid[0]){
        setValid((prevValid) => [false, prevValid[1]])
    }

    return( 
        <Container className='quote'>
            <PageTitle title='DHCS - Quote' />
            {/* Create a custom form similar to the one I was previously working on */}
            {
                quote ? 
                <iframe 
                    title='Book Cleaning With Launch27 Form' 
                    id='booking-widget-iframe' 
                    src='https://danishomecleaningservices.launch27.com/?w_cleaning' 
                    style={style}
                />: 
                <div>
                    <h1 className='fw-500'>Find out if we provide our service to your area.</h1>
                    
                    <Form validated={valid[0]}>
                        <Form.Label className='fw-500'>Zip Code</Form.Label>
                        <Form.Control
                            type='text'
                            onChange={handleChange}
                            value={zip}
                            onPaste={handlePaste}
                            isInvalid={!valid}
                            className={(!valid[0] && valid[1] === 5) ? 'invalid-text' : ''}
                        />
                        <Form.Control.Feedback type='invalid'>
                            Please enter a valid 5-digit zip code.
                        </Form.Control.Feedback>
                    </Form>
                    {   valid[1] === 5 && 
                        (
                            valid[0] ? 
                            <p className='valid'>We provide our service to your area!</p>:
                            <p className='invalid'>At this moment we do not offer our service to your area.</p>

                        )
                    }
                    <Button variant='outline-primary' onClick={handleClick}>Get Instant Quote</Button>
                </div>
            }
            <Helmet>
                <script src='https://danishomecleaningservices.launch27.com/jsbundle'></script>
            </Helmet>
        </Container>
    )
}