import React, { Suspense, lazy } from 'react'
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import PageTitle from '../components/PageTitle';

// Media
import kitchenLgWeb from '../../media/kitchen-lg.webp'
import kitchenMdWeb from '../../media/kitchen-md.webp'
import kitchenSmWeb from '../../media/kitchen-sm.webp'

import bedroomLgWeb from '../../media/bedroom-lg.webp'
import bedroomMdWeb from '../../media/bedroom-md.webp'
import bedroomSmWeb from '../../media/bedroom-sm.webp'

import bathroomLgWeb from '../../media/bathroom-lg.webp'
import bathroomMdWeb from '../../media/bathroom-md.webp'
import bathroomSmWeb from '../../media/bathroom-sm.webp'

import Spinner from 'react-bootstrap/Spinner';
const LazySoTellUs = lazy(() => import('../components/SoTellUs'));

export default function Home({poster}){
    let posterImg = null
    if (poster) {
        posterImg = require(`../../media/${poster.img}`)
    }
    
    return (
        <Container>
            <PageTitle title='DHCS' />

            <h1>Welcome to Dani's Home Cleaning Services</h1>
            <p className='fw-500'>A place where trust, quality, and family values come together to offer you the best in house cleaning services.</p>

            {
                poster &&
                <img
                    className='img-fluid'
                    src={posterImg}
                    alt={poster.alt} 
                    width={poster.width} 
                    height={poster.height} 
                    style={{maxWidth: '100%', height: 'auto'}}
                    fetchpriority='high'
                />
            }
            
            <Carousel interval={null}>
                <Carousel.Item>
                    <picture>
                        <source srcSet={`${kitchenSmWeb} 516w, ${kitchenMdWeb} 696w, ${kitchenLgWeb} 1600w`} type="image/webp"/>
                        <img 
                            src={kitchenSmWeb} 
                            alt='clean modern kitchen'
                            width="876" 
                            height="500" 
                            style={{maxWidth: '100%', height: 'auto' }}
                            fetchpriority='high'
                        />
                    </picture>

                </Carousel.Item>
                <Carousel.Item>
                    <picture>
                        <source srcSet={`${bedroomSmWeb} 516w, ${bedroomMdWeb} 696w, ${bedroomLgWeb} 1600w`} type="image/webp"/>
                        <img 
                            src={bedroomSmWeb} 
                            alt='clean bedroom'
                            width="876" 
                            height="500" 
                            style={{maxWidth: '100%', height: 'auto'}}
                            fetchpriority='high'
                        />
                    </picture>
                </Carousel.Item>
                <Carousel.Item>
                    <picture>
                        <source srcSet={`${bathroomSmWeb} 516w, ${bathroomMdWeb} 696w, ${bathroomLgWeb} 1600w`} type="image/webp"/>
                        <img 
                            src={bathroomSmWeb} 
                            alt='clean bathroom'
                            width="876" 
                            height="500" 
                            style={{maxWidth: '100%', height: 'auto'}}
                            fetchpriority='high'
                        />
                    </picture>
                </Carousel.Item>
            </Carousel>
            <Suspense fallback={
                <div>
                    <Spinner animation="border" variant="secondary" />
                </div>
            }>
                <LazySoTellUs/>
            </Suspense>
        </Container>
    )
}