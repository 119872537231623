import React from 'react'
import { NavLink, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

// Media
import logoSmWeb from '../../media/logo-sm.webp'
import dhcsWeb from '../../media/dhcs.webp'
import dhcsSmWeb from '../../media/dhcs-sm.webp'


export default function Header(){
    return(
        <header>
            <Navbar fixed='top' collapseOnSelect expand='lg' className='bg-body-tertiary shadow'>
                <Container>
                    <Navbar.Brand as={NavLink} to='/'>
                        <picture className='d-inline-block align-top me-2'>
                            <img 
                                src={logoSmWeb} 
                                width='70'
                                height='58'
                                alt='company logo'
                            />
                        </picture>

                        <picture className='d-inline-block align-bottom'>
                            <source srcSet={`${dhcsSmWeb} 317w, ${dhcsWeb} 633w`} type="image/webp"/>
                            <img 
                                src={dhcsSmWeb} 
                                width='200'
                                height='50'
                                alt='company logo text'
                            />
                        </picture>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                    <Navbar.Collapse id='responsive-navbar-nav'>
                        <Nav className='ms-auto' id='nav-items'>
                            <Nav.Link as={NavLink} to='/'><span>Home</span></Nav.Link>
                            <Nav.Link as={NavLink} to='about-us'><span>About Us</span></Nav.Link>
                            <NavDropdown title=<span>Cleaning Services</span> id='collapsible-nav-dropdown'>
                                <NavDropdown.Item as={Link} to='standard-clean'>Standard Clean</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='deep-clean'>Deep Clean</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='move-in-out-clean'>Move In/Out Clean</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item as={Link} to='cleaning-checklist'>Cleaning Checklist</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link as={NavLink} to='reviews'><span>Reviews</span></Nav.Link>
                            <Nav.Link as={NavLink} to='gift-card'><span>Gift Card</span></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}