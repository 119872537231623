import React from 'react'
import Container from 'react-bootstrap/Container';
import PageTitle from '../components/PageTitle'

export default function GiftCard(){
    const style = {
        border: 'none',
        width: '100%',
        maxWidth: '752px',
        minHeight: '1260px',
    }

    React.useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://danishomecleaningservices.launch27.com/jsbundle';
        script.async = true;
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script)
        };
    }, []);

    return (
        <Container className='gift-card'>
            <PageTitle title='DHCS - Gift Card' />

            <iframe 
                id='booking-widget-iframe' 
                title='Gift card form from Launch27' 
                src='https://danishomecleaningservices.launch27.com/giftcards/new?w' 
                style={style} 
            />
        </Container>
    )
}