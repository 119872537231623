import React from 'react'
import Container from 'react-bootstrap/Container';
import SoTellUs from '../components/SoTellUs';
import PageTitle from '../components/PageTitle';

export default function Reviews(){
    return (
        <Container>
            <PageTitle title='DHCS - Reviews' />

            <h1>Our Dear Clients Say</h1>
            <p className='fw-500 text-center'>We are pleased to share the testimonials of our satisfied customers.</p>
            <SoTellUs height='600'/>
        </Container>
    )
}