import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import { IoLogoWhatsapp,  } from 'react-icons/io';
import { IoLogoFacebook } from 'react-icons/io5';
import { RiInstagramFill, RiMessengerFill } from 'react-icons/ri';
import Container from 'react-bootstrap/Container';

// Media
import logoSmWeb from '../../media/logo-sm.webp'
import dhcsWeb from '../../media/dhcs.webp'
import dhcsSmWeb from '../../media/dhcs-sm.webp'
import ahcaSmWeb from '../../media/ahca-sm.webp'
import bbbSmWeb from '../../media/bbb-a-rating-sm.webp'
import speedCleaningSmWeb from '../../media/speed-cleaning-sm.webp'


export default function Footer(){
    return (
        <>
            <footer className='footer'>
                <Container>
                    <div className='footer--imgs'>
                        <picture className='d-inline-block align-top me-2'>
                            <img 
                                src={logoSmWeb} 
                                width='70'
                                height='58'
                                alt='company logo'
                            />
                        </picture>

                        <picture className='d-inline-block align-bottom'>
                            <source srcSet={`${dhcsSmWeb} 317w, ${dhcsWeb} 633w`} type="image/webp"/>
                            <img 
                                src={dhcsSmWeb} 
                                width='200'
                                height='50'
                                alt='company logo text'
                            />
                        </picture>
                        
                        <picture className='d-inline-block align-bottom'>
                            <img 
                                src={bbbSmWeb} 
                                width='125'
                                height='60'
                                alt='BBB A+ Rating'
                            />
                        </picture>

                        <picture className='d-inline-block align-bottom'>
                            <img 
                                src={ahcaSmWeb} 
                                width='75'
                                height='85'
                                alt='Certified Professional House Cleaner'
                            />
                        </picture>

                        <picture className='d-inline-block align-bottom'>
                            <img 
                                src={speedCleaningSmWeb}
                                width='100'
                                height='100'
                                alt='Speed Cleaning Certified 2024'
                            />
                        </picture>
                    </div>
                    <div className='footer--info'>
                        <div className='footer--links'>
                            <NavLink to='privacy-policy'>
                                <span>Privacy Policy</span>
                            </NavLink>
                            <NavLink to='faqs'>
                                <span>FAQs</span>
                            </NavLink>
                            <NavLink to='terms'>
                                <span>Terms & Conditions</span>
                            </NavLink>
                            <NavLink to='contact-us'>
                                <span>Contact Us</span>
                            </NavLink>
                        </div>
                        <ul className='no-style-list'>
                            <li className='geo'>
                                6 Cresson Avenue <br/>
                                New Castle, DE 19720
                            </li>
                            <li className='time'>
                                <span>Monday - Friday</span>
                                <span className='hours'>8AM - 6PM</span>
                                <span>Saturday</span>
                                <span className='hours'>8AM - 2PM</span>
                            </li>
                            <li className='phone'>
                                <a href='tel:302-525-8286'>
                                    302-525-8286
                                </a>
                            </li>
                            <li className='email'>
                                jato8158@gmail.com
                            </li>
                        </ul>
                    </div>
                    <hr/>
                    <div className='footer--creds'>
                        <div>
                            ©2024 DHCS, LLC | All rights reserved.
                        </div>
                        <div>
                            <a href='https://wa.me/16105637062' aria-label="Dani's Home Cleaning Services' WhatsApp">
                                <IoLogoWhatsapp />
                            </a>
                            <a href='https://m.me/DanielasHomeCleaningServices/' aria-label="Dani's Home Cleaning Services' Messenger">
                                <RiMessengerFill />
                            </a>
                            <a href='https://www.facebook.com/DanielasHomeCleaningServices/' aria-label="Dani's Home Cleaning Services' Facebook">
                                <IoLogoFacebook />
                            </a>
                            <a href='https://www.instagram.com/danishomecleaning/?utm_source=ig_profile_share&igshid=1qdvikp0n7d64' aria-label="Dani's Home Cleaning Services' Instagram">
                                <RiInstagramFill />
                            </a>
                        </div>
                    </div>
                </Container>
            </footer>
            <footer className='fixed-footer'>
                <Container>
                    <Link to='quote' role='button' className='btn custom-btn'>INSTANT QUOTE!</Link>
                    <a href='tel:302-525-8286' role='button' className='btn custom-btn'>
                        <i className='bi bi-telephone-fill'></i>
                        302-525-8286
                    </a>
                </Container>
            </footer>
        </>
    )
}