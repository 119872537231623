import React from 'react'
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import PageTitle from '../components/PageTitle';

export default function ContactUs(){
    const style = {
        border: 'none',
        width: '100%',
        minHeight: '550px',
        maxWidth: '500px',
    }

    return(
        <Container>
            <PageTitle title='DHCS - Contact Us' />

            <h1>Contact Us</h1>
            <div className='contact'>
                <ul className='no-style-list'>
                    <li className='phone'>
                        <span>Phone</span><br />
                        302-525-8286
                    </li>
                    <li className='email'>
                        <span>Email</span><br />
                        jato8158@gmail.com
                    </li>
                    <li className='geo'>
                        <span>Address</span><br />
                        6 Cresson Avenue New Castle, DE 19720
                    </li>
                    <li className='time'>
                        <span>Operating Hours</span><br />
                        <span>Monday - Friday</span>
                        <span className='hours'>8AM - 6PM</span>
                        <span>Saturday</span>
                        <span className='hours'>8AM - 2PM</span>
                    </li>
                </ul>
                <iframe 
                    id='booking-widget-iframe' 
                    title='Send message form from Launch27' 
                    src='https://danishomecleaningservices.launch27.com/quotes/new?w' 
                    style={style}
                />
                <Helmet>
                    <script src='https://danishomecleaningservices.launch27.com/jsbundle'></script>
                </Helmet>
            </div>
        </Container>
    )
}