import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import Favicon from './Favicon';

export default function Layout(){
    return (
        <>  
            <Favicon />
            <Header />
            <main>
                <Outlet />
                <call-us-selector 
                    phonesystem-url='https://1739.3cx.cloud' 
                    party='dani39shomecleanings'
                    aria-label="Live Chat for Dani's Home Cleanings"
                />
            </main>
            <Footer />
        </>
    )
}