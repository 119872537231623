import React, { Suspense, lazy } from 'react'
import Container from 'react-bootstrap/Container';
import CleaningList from '../components/CleaningList';
import PageTitle from '../components/PageTitle';

import Spinner from 'react-bootstrap/Spinner';
const LazySoTellUs = lazy(() => import('../components/SoTellUs'));

export default function Deep(){
    return (
        <Container>
            <PageTitle title='DHCS - Deep Clean' />

            <h1>Deep Clean</h1>
            <div className='clean'>
                <div className='space'>
                    <p className='fw-500'>Get Your Home Meticulously Clean With Our Deep Cleaning Service</p>
                    <p>When it comes to getting your home really clean, our deep house cleaning services will get the job done. Our professional deep cleaners specialize in delivering a thorough and comprehensive cleaning that goes well beyond surface cleaning. With meticulous attention to detail, we target those hard-to-reach areas and hidden corners that often accumulate dirt, grime, and allergens.</p>
                    <p className='mb-0'>Our deep house cleaning service includes:</p>
                    <ul>
                        <li>Hand washing baseboards and other woodwork throughout the home.</li>
                        <li>A thorough cleaning of the kitchen, including wiping down cabinets, backsplash, and appliances.</li>
                        <li>Sanitizing and disinfecting of bathroom surfaces.</li>
                        <li>Removal of built-up soap scum and mildew from showers and tubs.</li>
                        <li>Detailed dusting of the entire home.</li>
                        <li>Expert vacuuming and mopping of all floors.</li>
                    </ul>

                    <CleaningList addOns={true}/>

                    <p>We use top-quality cleaning products and state-of-the-art equipment to ensure your home not only looks immaculate but is also a healthier environment for you and your family.</p>
                    <p>* We do not climb higher than a 2-step ladder. Higher items will only be dusted w/ an extension duster we cannot move furniture, but we will try to reach any visible places either by hand or with an extension duster. Most homes have at least one chandelier or vaulted ceiling fan that we cannot reach by hand.</p>
                    <p>* We cannot guarantee the results of mini-blind cleaning. Please allow for some dust resettlement after we leave, we try to limit the dust in the air but cannot prevent this entirely.</p>
                </div>    
                <Suspense fallback={
                    <div>
                        <Spinner animation="border" variant="secondary" />
                    </div>
                }>
                    <LazySoTellUs/>
                </Suspense>
            </div>
        </Container>
    )
}