import React, { Suspense, lazy } from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import CleaningList from '../components/CleaningList';
import PageTitle from '../components/PageTitle';

import Spinner from 'react-bootstrap/Spinner';
const LazySoTellUs = lazy(() => import('../components/SoTellUs'));

export default function Standard(){
    return (
        <Container>
            <PageTitle title='DHCS - Standard Clean' />

            <h1>Standard Clean</h1>
            <div className='clean'>
                <div className='space'>
                    <p className='fw-500'>For your ongoing, weekly, bi-weekly, or monthly services, your maintenance cleanings include the following:</p>

                    <CleaningList addOns={true}/>

                    <p>It is presumed that the home will be in order and somewhat picked up to facilitate cleaning the areas describe above.</p>
                    <p className='fst-italic'>This service is not recommended for move in / move out cleans, or first time initial cleans.</p>
                    <p>Learn about our <Link to='/deep-clean' className='link'>Deep Clean</Link>!</p>
                </div>
                <Suspense fallback={
                    <div>
                        <Spinner animation="border" variant="secondary" />
                    </div>
                }>
                    <LazySoTellUs/>
                </Suspense>
            </div>
        </Container>
    )
}