import React from 'react';
import logo from '../../media/logo.png';

export default function Favicon () {
    React.useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'icon';
        link.type = 'image/png';
        link.href = logo;
        document.head.appendChild(link);

        return () => {
            document.head.removeChild(link);
        };
    }, []);
    return null;
};